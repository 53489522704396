import * as dayjs from "dayjs";

/// Override date.toJSON method because otherwise it will NOT maintain timezone.
// eslint-disable-next-line no-extend-native
Date.prototype.toJSON = function() {
    return dayjs(this).format("YYYY-MM-DDTHH:mm:ss");
};

export const addDays = (date: Date, days: number): Date => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
};

export const getDateAsString = (dateValue: Date): string => {
    const date = new Date(dateValue);
    const dateText = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    return dateText;
}

export const formatDate = (datevalue?: Date) => {
    if (!datevalue) {
        return "";
    }
    const date = new Date(datevalue);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const hoursText = hours < 10 ? "0" + hours : hours;
    const minutesText = minutes < 10 ? "0" + minutes : minutes;
    const dateText = `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`;
    return `${hoursText}:${minutesText} ${dateText}`;
};

export const formatComplaintDate = (datevalue: Date) => {
    if (!datevalue) {
        return "";
    }
    const date = new Date(datevalue);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const hoursText = hours < 10 ? "0" + hours : hours;
    const minutesText = minutes < 10 ? "0" + minutes : minutes;
    const dateText = `${date.getDate()}-${date.getMonth() +
        1}-${date.getFullYear()}`;
    return `${hoursText}:${minutesText} ${dateText}`;
};

export const convertServerDate = (date: Date) => {
    return new Date(Date.parse(date.toString()));
};

export const getServerDate = (date: Date) => {
    return convertServerDate(date)
        .toISOString()
        .substring(0, 10)
        .split("-")
        .reverse()
        .join("-");
};

export const getDaysInMonth = (month: number, year: number): Date[] => {
    const date = new Date(year, month, 1);
    const days = [];
    while (date.getMonth() === month) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }
    return days;
};

export const mapDecimalToTimeString = (decimal: number): string => {
    const decimalTimeString = decimal.toString();
    const n = new Date(0, 0);
    n.setSeconds(+decimalTimeString * 60 * 60);
    const result = n.toTimeString().slice(0, 5);
    return result;
};

const shouldChangeEntireWindow = (damage: AssignmentType): boolean => {
    return damage.indexOf("REPLACE") > -1;
};

export const getFirstAvailableDay = (
    damage: AssignmentType,
    department?: Department
) => {
    const mustChangeWindow = shouldChangeEntireWindow(damage);
    if (mustChangeWindow && department && department.firstAvailableBookingWindowReplaceTimeSlot ) {
        const stringDate = department.firstAvailableBookingWindowReplaceTimeSlot.date;
        if (stringDate) {
            return new Date(stringDate);
        }
    }
    if (!mustChangeWindow && department && department.firstAvailableBookingStoneChipTimeSlot ) {
        const stringDate = department.firstAvailableBookingStoneChipTimeSlot.date;
        if (stringDate) {
            return new Date(stringDate);
        }
    }
    return  new Date();
};


export const canBookAtDate = (
    date: Date,
    availableDepartmentBookings: AvailableDepartmentBooking[],
    orderType: AssignmentType,
    firstAvailableDay: Date,
) => {

    if(date.getFullYear() <= firstAvailableDay.getFullYear() && date.getMonth() <= firstAvailableDay.getMonth() && date.getDate() < firstAvailableDay.getDate())
    {
        return false;
    }
    const availableBooking = availableDepartmentBookings.find(
        x => x.dayOfMonth === date.getDate()
    );
    if (availableBooking) {
        if (orderType.indexOf("REPAIR")>-1) {
          
            return availableBooking.stoneChipAvailable;
          
        } else {
           return availableBooking.windowReplaceAvailable;
        }
    }
    return false;
};

export const prependZeroIFLessThen10 = (i: number) => (i < 10 ? "0" + i : i);

export const getNextWeekday = (date: Date): Date => 
{
    let nextDate = new Date(date);
    let day = nextDate.getDay();

    // If it's Friday (5), Saturday (6), or Sunday (0), skip to Monday (1)
    if (day === 5) {
        nextDate.setDate(nextDate.getDate() + 3); // Friday to Monday
    } else if (day === 6) {
        nextDate.setDate(nextDate.getDate() + 2); // Saturday to Monday
    } else if (day === 0) {
        nextDate.setDate(nextDate.getDate() + 1); // Sunday to Monday
    } else {
        nextDate.setDate(nextDate.getDate() + 1); // For other weekdays
    }

    return nextDate;
}

